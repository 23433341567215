/**
 * Hook to monitor and set the EditorChanged variable
 */
import { useState, useEffect } from 'react';
import EditorChanged from '../editor-changed';

const useEditorChanged = (): boolean => {
    const [changed, setChanged] = useState(EditorChanged.changed);

    useEffect(() => {
        const handleChange = (newChanged: boolean) => {
            setChanged(newChanged);
        };

        EditorChanged.subscribe(handleChange);

        return () => {
            EditorChanged.unsubscribe(handleChange);
        };
    }, []);

    return changed;
};

export default useEditorChanged;
